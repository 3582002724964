import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  // let header

  // if (isRootPath) {
  //   header = (
  //     <>
  //       <h1 className="main-heading">
  //         <Link to="/">{title}<span className="color-primary">.</span></Link>
  //       </h1>
  //       <p>by <a href="https://moka.my.id">@mokanh</a></p>
  //     </>
  //   )
  // } else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">
        <h1 className="main-heading">
          <Link to="/">{title}<span className="color-primary">.</span></Link>
        </h1>
        <p>by <a href="https://moka.my.id">@mokanh</a></p></header>
      <main>{children}</main>
      {/* <footer>
        © {new Date().getFullYear()},
        {` `}
        <a href="https://www.moka.my.id">@MOKANH</a>
      </footer> */}
    </div>
  )
}

export default Layout
